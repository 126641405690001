import fetch from 'auth/FetchInterceptor'

const apiService = {}

/**
 * Get list of items at API endpoint
 * @param endPoint
 * @param params
 * @returns {AxiosPromise}
 */
apiService.getItems = function (endPoint, params) {
    if (!params) params = {};
    params.ts = Date.now();
    return fetch({
        url: '/api/' + endPoint,
        method: 'get',
        params
    })
}

apiService.getByRid = function (rid, params = {}) {
    if (!params) params = {};
    params.ts = Date.now();

    if (typeof rid !== 'string' || rid.indexOf('/api/') < 0) {
        return new Promise((resolve, reject) => {
            reject('rid/iri is incorrect');
        });
    }

    return fetch({
        url: rid,
        method: 'get',
        params
    })
}

/**
 * Get single item at API endpoint
 * @param endPoint
 * @param id
 * @returns {AxiosPromise}
 */
apiService.getItem = function (endPoint, id) {
    const params = {"ts": Date.now()};
    return fetch({
        url: '/api/' + endPoint + '/' + id,
        method: 'get',
        params
    })
}

/**
 * Create new item at API endpoint
 * @param endPoint
 * @param data
 * @returns {AxiosPromise}
 */
apiService.createItem = function (endPoint, data) {
    return fetch({
        url: '/api/' + endPoint,
        method: 'post',
        data: data
    })
}

/**
 * Update single item at API endpoint
 * @param endPoint
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
apiService.updateItem = function (endPoint, id, data) {
    return fetch({
        url: '/api/' + endPoint + '/' + id,
        method: 'put',
        data: data
    })
}

/**
 * Delete a single item at API endpoint
 * @param endPoint
 * @param id
 * @returns {AxiosPromise}
 */
apiService.deleteItem = function (endPoint, id) {
    return fetch({
        url: '/api/' + endPoint + '/' + id,
        method: 'delete'
    })
}

export default apiService
