import {Badge} from "antd";
import useFetch from "hooks/useFetch";


/**
 * Open refunds counter badge
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const RefundBadge = (props) => {

    const {data, totalItems} = useFetch('refunds', {
        pagination: {},
        query: {isApproved: 0}
    });

    if (data && props.setOpenRefunds) {
        props.setOpenRefunds(totalItems);
    }

    return <Badge count={props.count}>{props.children}</Badge>;
}

export default RefundBadge;
