import React, {useEffect, useState} from "react";
import {Avatar, Dropdown, message, Space} from "antd";
import {
    ApiOutlined,
    BookOutlined,
    EditOutlined,
    LogoutOutlined,
    QrcodeOutlined,
    SettingOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {signOut} from '../../redux/actions/Auth';
import {Link, useHistory} from "react-router-dom";
import {API_BASE_URL, APP_PREFIX_PATH} from "../../configs/AppConfig";
import apiService from "../../services/ApiService";
import {useDispatch, useSelector} from "react-redux";
import IntlMessage from "components/util-components/IntlMessage";
import userService from "../../services/UserService";
import {useIntl} from "react-intl";

const NavProfile = () => {
    const intl = useIntl();
    const {user, scope} = useSelector((state) => state.auth);
    const [partner, setPartner] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (scope) {
            apiService.getByRid(scope).then((result) => {
                setPartner(result);
            }).catch(e => console.log('scope_error', e))
        }
    }, [scope]);

    const printQR = () => {
        window.open(API_BASE_URL + '/walk-in-qr/' + partner.uniqueHash + '.pdf', '_blank');
        message.success(intl.formatMessage({id: "nav_profile.download_qr"}));
    }

    /**
     * Do logout
     */
    const doLogout = () => {
        dispatch(signOut());
        history.push('/auth/login');
    }

    /**
     * Get authentication subtext
     * @returns {Element}
     */
    const getAuthSubtext = () => {
        if (partner) {
            return <p>{partner.companyName}</p>;
        }
        return <p><IntlMessage id={"common.welcome"} /></p>
    }

    const userRole = userService.getRoleType(user.roles)
    const items = [
        {
            key: 'avatar',
            label: (
                <Space size={"small"} align={"center"} className={"align-items-center"}>
                    <Avatar style={{color: '#f56a00', backgroundColor: '#fde3cf'}}>
                        {user?.firstname.slice(0, 1)}
                    </Avatar>
                    <div className="ml-2">
                        <h4 className="mb-0">{user?.firstname + ' ' + user?.lastname}</h4>
                        {getAuthSubtext()}
                    </div>
                </Space>
            )
        },
        {
            key: 'profile',
            label: (
                <Link
                    to={`${APP_PREFIX_PATH}${userRole === 'admin' ? '/administrators/edit/' + user.id : '/profile/edit'}`}>
                    <Icon className="mr-3" type={EditOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"common.profile_edit"} /></span>
                </Link>
            )
        },
        userService.isAllowed(user, ['ROLE_PARTNER']) && {
            key: 'locations',
            label: (
                <Link to={`${APP_PREFIX_PATH}/locations`}>
                    <Icon className="mr-3" type={BookOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"address_book.title"}/></span>
                </Link>
            )
        },
        partner?.hasWalkIn && {
            key: 'walk-in',
            label: (
                <span onClick={printQR}>
                    <Icon className="mr-3" type={QrcodeOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"nav_profile.print_qr"}/></span>
                </span>
            )
        },
        userService.isAllowed(user, ['ROLE_PARTNER']) && {
            key: 'connectors',
            label: (
                <Link to={`${APP_PREFIX_PATH}/connectors`}>
                    <Icon className="mr-3" type={ApiOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"common.connectors"}/></span>
                </Link>
            )
        },
        userRole === 'partner' && {
            key: 'conditions',
            label: (
                <a href="/docs/Partnervoorwaarden-workit-29.6.23.pdf" target="_blank">
                    <Icon className="mr-3" type={BookOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"common.partner_conditions"}/></span>
                </a>
            )
        },
        userRole === 'partner' && {
            key: 'profile-nav-settings',
            label: (
                <Link to={`${APP_PREFIX_PATH}/settings`}>
                    <Icon className="mr-3" type={SettingOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"common.settings"}/></span>
                </Link>
            )
        },
        {
            key: 'profile-nav-logout',
            danger: true,
            label: (
                <span onClick={doLogout}>
                    <Icon className="mr-3" type={LogoutOutlined}/>
                    <span className="font-weight-normal"><IntlMessage id={"common.log_out"}/></span>
                </span>
            )
        }
    ];

    return (
        <Dropdown placement="bottomRight" menu={{items}}>
            <span onClick={(e) => e.preventDefault()}>
                <Avatar style={{color: '#f56a00', backgroundColor: '#fde3cf'}}>
                    {user?.firstname.slice(0, 1)}
                </Avatar>
            </span>
        </Dropdown>
    )
}

export default NavProfile;
