import {REFUNDS_OPEN} from "../constants/Refunds";

const initState = {
    count: 0
}

/**
 * Refunds Redux state
 * @param state
 * @param action
 * @return {{count}|{count: number}}
 */
const refunds = (state = initState, action) => {
    switch (action.type) {
        case REFUNDS_OPEN:
            return {
                ...state,
                count: action.count
            }
        default:
            return state;
    }
};

export default refunds;
