import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Layout, Space} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Logo from './Logo';
import NavSearch from './NavSearch';
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import utils from 'utils'
import NavProfile from "./NavProfile";
import LanguageSwitch from "../shared-components/LanguageSwitch/LanguageSwitch";

const {Header} = Layout;

export const HeaderNav = props => {
    const {
        navCollapsed,
        mobileNav,
        navType,
        headerNavColor,
        toggleCollapsedNav,
        onMobileNavToggle,
        isMobile,
        currentTheme,
    } = props;
    const [searchActive, setSearchActive] = useState(false)

    const onSearchClose = () => {
        setSearchActive(false)
    }

    const onToggle = () => {
        if (!isMobile) {
            toggleCollapsedNav(!navCollapsed)
        } else {
            onMobileNavToggle(!mobileNav)
        }
    }

    const isNavTop = navType === NAV_TYPE_TOP ? true : false
    const mode = () => {
        if (!headerNavColor) {
            return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#fff')
        }
        return utils.getColorContrast('#000')
    }
    const navMode = mode()
    const getNavWidth = () => {
        if (isNavTop || isMobile) {
            return '0px'
        }
        if (navCollapsed) {
            return `${SIDE_NAV_COLLAPSED_WIDTH}px`
        } else {
            return `${SIDE_NAV_WIDTH}px`
        }
    }

    useEffect(() => {
        if (!isMobile) {
            onSearchClose()
        }
    })

    return (
        <Header className={`app-header ${navMode}`} style={{backgroundColor: '#0f1c2f'}}>
            <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
                <Logo logoType={navMode}/>
                <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
                    <div className="nav-left">
                        <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                            {
                                isNavTop && !isMobile ?
                                    null
                                    :
                                    <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {
                                        onToggle()
                                    }}>
                                        {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon"/> :
                                            <MenuFoldOutlined className="nav-icon"/>}
                                    </li>
                            }
                        </ul>
                    </div>

                    <div className="nav-right">
                        <Space size={"large"}>
                            <LanguageSwitch/>
                            <NavProfile/>
                        </Space>
                    </div>
                    <NavSearch active={searchActive} close={onSearchClose}/>
                </div>
            </div>
        </Header>
    )
}

const mapStateToProps = ({theme}) => {
    const {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction} = theme;
    return {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction}
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);