import {
    AUTH_SCOPE,
    AUTH_TOKEN,
    AUTH_USER_TYPE,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    SCOPE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNIN_AS,
    SIGNIN_WITH_FACEBOOK,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SIGNIN_WITH_GOOGLE,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNOUT,
    SIGNOUT_AS,
    SIGNOUT_SUCCESS,
    SIGNUP,
    SIGNUP_SUCCESS
} from '../constants/Auth';

export const signIn = (user, scope, company) => {
    localStorage.setItem(AUTH_USER_TYPE, JSON.stringify(user.roles))
    if (scope) {
        localStorage.setItem(AUTH_SCOPE, scope);
    } else {
        localStorage.removeItem(AUTH_SCOPE);
    }
    return {
        type: SIGNIN,
        user,
        scope,
        company
    }
};


export const signInAs = (user, scope, company) => {
    localStorage.setItem(AUTH_USER_TYPE, JSON.stringify(user.roles))
    localStorage.setItem(SIGNIN_AS, user['@id']);

    if (scope) {
        setScope(scope);
    }
    return {
        type: SIGNIN_AS,
        user,
        scope,
        company
    }
}

export const setScope = (scope) => {
    localStorage.setItem(AUTH_SCOPE, scope);
    return {
        type: SCOPE,
        scope
    }
}

export const authenticated = (token) => {
    localStorage.setItem(AUTH_TOKEN, token)
    return {
        type: AUTHENTICATED,
        token
    }
};

export const signOut = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(SIGNIN_AS);
    localStorage.removeItem(AUTH_SCOPE);
    localStorage.removeItem(AUTH_USER_TYPE);
    return {
        type: SIGNOUT
    };
};

export const signOutAs = () => {
    const authUserType = JSON.parse(localStorage.getItem(AUTH_USER_TYPE));

    let redirectUrl = '/app/partners';
    if (authUserType) {
        if (authUserType.includes("ROLE_COMPANY")) {
            redirectUrl = '/app/companies';
        }
        if (authUserType.includes("ROLE_PARTNER_CHAIN")) {
            redirectUrl = '/app/partner-chains';
        }
    }

    // clear partner scope storage
    // this must be cleared otherwise admin users will clutter up their local storage
    let removeStorageKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
        const storageKey = localStorage.key(i);
        if (storageKey.indexOf('partner_') >= 0) {
            removeStorageKeys.push(storageKey);
        }
    }
    removeStorageKeys.forEach((key) => {
        localStorage.removeItem(key);
    });

    localStorage.removeItem(SIGNIN_AS);
    localStorage.removeItem(AUTH_SCOPE);
    localStorage.removeItem(AUTH_USER_TYPE);
    window.location.href = redirectUrl;
    return {
        type: SIGNOUT_AS
    }
}

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS,
    }
};

export const signUp = (user) => {
    return {
        type: SIGNUP,
        payload: user
    };
};

export const signUpSuccess = (token) => {
    return {
        type: SIGNUP_SUCCESS,
        token
    };
};

export const signInWithGoogle = () => {
    return {
        type: SIGNIN_WITH_GOOGLE
    };
};

export const signInWithGoogleAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
        token
    };
};

export const signInWithFacebook = () => {
    return {
        type: SIGNIN_WITH_FACEBOOK
    };
};

export const signInWithFacebookAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
        token
    };
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_AUTH_MESSAGE,
        message
    };
};

export const hideAuthMessage = () => {
    return {
        type: HIDE_AUTH_MESSAGE,
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING,
    };
};
