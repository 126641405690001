const dev = {
    API_ENDPOINT_URL: 'https://localhost:8000'
};

const prod = {
    API_ENDPOINT_URL: 'https://api.workit.nl'
};

const preview = {
    API_ENDPOINT_URL: 'https://preview.api.workit.nl'
};

const getEnv = () => {
    switch (process.env.REACT_APP_VERCEL_ENV) {
        case 'preview':
            return preview
        case 'production':
            return prod
        case 'dev':
        default:
            return dev
    }
}

export const env = getEnv()
