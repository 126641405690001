import {useDispatch, useSelector} from "react-redux";
import {setLocale} from "../redux/actions";
import apiService from "../services/ApiService";

export default function useLanguageSwitcher() {
    const dispatch = useDispatch();
    const activeLocale = useSelector(state => state.theme.locale);
    const user = useSelector(state => state.auth.user)

    const set = (locale = 'nl') => {
        if (user) {
            apiService.updateItem('users', user.id, {locale: locale});
        }
        dispatch(setLocale(locale));
    }

    const active = () => {
        return activeLocale;
    }

    return {
        set: set,
        active: active
    };
}