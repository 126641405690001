import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {useStore} from "react-redux";
import NavigationConfig from "configs/NavigationConfig";
import userService from "../../services/UserService";

export const AdminViews = () => {

    const store = useStore();
    const state = store.getState();
    const userRole = userService.getRoleType(state.auth?.user?.roles);

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                {typeof NavigationConfig[userRole] !== 'undefined' && NavigationConfig[userRole].map((row, key) => {

                    if (row.security?.allow) {
                        if (!userService.isAllowed(state.auth.user, row.security.allow)) {
                            return null;
                        }
                    }

                    let subRoutes = [];
                    if( row.submenu ){
                        subRoutes = row.submenu.map((subRow, subkey) => {
                            return (<Route path={subRow.path} key={`route-sub-${subkey}`} component={lazy(() => subRow.component)}/>)
                        })
                    }
                    if (row.component) {
                        return [...subRoutes, <Route exact path={row.path} key={`route-${key}`} component={lazy(() => row.component)}/>]
                    } else {
                        return subRoutes;
                    }
                })}
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`}/>
            </Switch>
        </Suspense>
    )
}

export default React.memo(AdminViews);
