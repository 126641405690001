import "./LanguageSwitcherStyles.css";
import {Space} from "antd";
import useLanguageSwitcher from "hooks/useLanguageSwitcher";

const LanguageSwitch = () => {
    const language = useLanguageSwitcher();

    return <Space size={"small"}>
        <button className={"lang-button " + (language.active() === 'nl' ? "active" : "")}
                onClick={() => language.set("nl")}>
            <img src="/img/flags/nl.svg" alt="Dutch"/>
        </button>
        <button className={"lang-button " + (language.active() === 'en' ? "active" : "")}
                onClick={() => language.set("en")}>
            <img src="/img/flags/gb.svg" alt="Dutch"/>
        </button>
    </Space>
}

export default LanguageSwitch;