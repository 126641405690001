import {Modal, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useRef, useState} from "react";
import userService from "services/UserService";
import {useIntl} from "react-intl";
import {setScope} from "../../../redux/actions/Auth";

/**
 * Scope switcher
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ScopeSwitcher = props => {
    const {user, scope} = useSelector(state => state.auth);
    const [selectedValue, setSelectedValue] = useState(scope);
    const intl = useIntl();
    let options = [];
    const selectInput = useRef(null);
    const dispatch = useDispatch();

    // check what to choose
    const role = userService.getRoleType(user?.roles);
    if (role === 'partner') {
        user.partner?.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.companyName
            })
        })
    } else if (role === 'company') {
        user.company?.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.name
            })
        })
    } else if (role === 'partnerChains') {
        user.partnerChains?.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.companyName
            })
        })
    }

    return (options.length === 1 ?
        <strong>{options[0].label}</strong> :
        <Select style={{width: '100%', fontWeight: 'bold'}} className={"ml-n2"}
                options={options}
                ref={selectInput}
                onChange={value => {
                    Modal.confirm({
                        title: intl.formatMessage({id: "scope_switcher.modal_title"}),
                        content: intl.formatMessage({id: "scope_switcher.modal_content"}),
                        confirmLoading: true,
                        onOk: () => {
                            dispatch(setScope(value));
                            window.location.reload();
                        },
                        onCancel: () => {
                            setSelectedValue(scope);
                        }
                    });
                }}
                value={selectedValue}/>);
}

export default ScopeSwitcher;